<template>
    <step-wrapper
        :index="index"
        :active="active"
        :valid="valid"
        :step="name"
        title="What channels do you want to deploy to?"
        :help-link="helpLink"
        description="Choose the channels you want to deploy this ad to below:">
        <styled-dialog v-model="dialogOpen">
            <div class="text-xs-center">
                <p>
                    Editing channels after they have been configured
                    will require re-completing all steps; however, channels
                    left active will retain their configuration.
                </p>
                <p>
                    Are you sure you want to continue?
                </p>
                <styled-button
                    class="mt-4"
                    @click="handleEdit">
                    YES, EDIT CHANNELS
                </styled-button>
            </div>
            <template #actions>
                <a
                    href="#"
                    class="red--text"
                    @click.prevent="dialogOpen = false">
                    Cancel
                </a>
            </template>
        </styled-dialog>
        <!-- Active state handled directly in selector -->
        <div class="channels">
            <channel-selector
                v-for="platform in availablePlatforms"
                :key="platform.name"
                :channel="platform.name"
                :active="active"
                :ads="platform.ads"
                @selection-checked="autoProceedToNextStep" />
            <styled-button
                v-if="!active"
                class="edit-button"
                text
                @click="dialogOpen = true">
                edit
            </styled-button>
        </div>
        <div
            v-if="!isStaticDeployment && hasManualDeploymentPlatformsSelected"
            class="manual-deployment-panel">
            <p>{{ $t('adDeployment.channelSelection.manualDeployment.mainMessage') }}</p>
            <ul>
                <li>{{ $t('adDeployment.channelSelection.manualDeployment.first') }}</li>
                <li>{{ $t('adDeployment.channelSelection.manualDeployment.second') }}</li>
                <li>{{ $t('adDeployment.channelSelection.manualDeployment.third') }}</li>
                <li>{{ $t('adDeployment.channelSelection.manualDeployment.fourth') }}</li>
                <li>{{ $t('adDeployment.channelSelection.manualDeployment.fifth') }}</li>
            </ul>
        </div>
        <new-campaign-selector
            v-if="active && isNewCampaignDeployment"
            @set-campaign="setCurrentCampaign" />
        <div
            v-else-if="!active && isNewCampaignDeployment"
            class="single-campaign-wrapper">
            <div class="single-campaign">
                <div class="single-campaign__main">
                    <span class="single-campaign__title">NEW CAMPAIGN</span>
                    <span class="single-campaign__name">{{ campaignName }}</span>
                </div>
                <div
                    class="single-campaign__bottom">
                    <span class="single-campaign__title">ADS CATEGORY</span>
                    <span class="single-campaign__name">{{ specialAdsCategoryName || specialAdsCategory }}</span>
                </div>
            </div>
        </div>
        <div
            v-if="error"
            class="error-message">
            {{ error }}
        </div>
        <div
            v-if="active"
            class="button-sad-holder">
            <styled-button
                small
                :disabled="!valid || loading"
                :loading="loading"
                @click="handleSubmit">
                <span>Continue</span>
            </styled-button>
        </div>
    </step-wrapper>
</template>

<script>
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import ChannelSelector from './ChannelSelector';
import StepWrapper from '../../components/StepWrapper';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import stepProps from '../stepProps';
import NewCampaignSelector from './NewCampaignSelector';
import {
    NEW_CAMPAIGN_DEPLOYMENT,
    DEPLOYMENT_PLATFORMS, DYNAMIC_DEPLOYMENT_TYPE,
} from '@/components/ad-deployment/store/constants';

export default {
    name: 'ChannelSelection',
    components: {
        StyledButton,
        ChannelSelector,
        StepWrapper,
        StyledDialog,
        NewCampaignSelector
    },
    props: stepProps,
    data() {
        return {
            loading: false,
            campaign: '',
            error: '',
            dialogOpen: false,
            channels: [],
            preSelections: [],
            helpLink: 'https://support.buyerbridge.com/knowledge/how-to-ad-launcher#preset-channel-selection'
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer,
            agency: state => state.agency.currentAgency,
            latestStep: state => state.adDeployment.latest_step,
            specialAdsCategory: (state) => state.adDeployment.campaign_selection.all_platforms.ad_category,
            campaignName: (state) => state.adDeployment.campaign_selection.all_platforms.campaign_name,
            selection: (state) => state.adDeployment.campaign_selection.selection,
            selectedChannels: (state) => state.adDeployment.platforms,
            dealerId: (state) => state.adDeployment.dealer_id,
            deploymentType: state => state.adDeployment.deployment_type,
            ads: state => state.adDeployment.ads,
        }),
        ...mapGetters([
            'dealerPlatforms',
            'dealerFeatures',
            'dealerFeaturesIncomplete',
            'dealerProducts'
        ]),
        ...mapGetters('adDeployment', [
            'availablePlatforms',
            'isStaticDeployment'
        ]),
        specialAdsCategoryName() {
            return this.specialAdsCategory.ui_name;
        },
        isNewCampaignDeployment() {
            return this.selection === NEW_CAMPAIGN_DEPLOYMENT;
        },
        hasManualDeploymentPlatformsSelected() {
            return this.active && this.selectedChannels.some(channel => {
                return DEPLOYMENT_PLATFORMS.find(platform => platform.name === channel && !platform.hasAutomatedInventoryDeploy);
            });
        },
    },
    methods: {
        ...mapActions({
            goToNextStep: 'adDeployment/goToNextStep'
        }),
        ...mapMutations({
            setAdDeploymentValue: 'adDeployment/setAdDeploymentValue'
        }),
        handleEdit() {
            this.$store.dispatch(
                'adDeployment/alterProgressToStepByName',
                this.name
            );
            this.dialogOpen = false;
        },
        autoProceedToNextStep(channel, preSelectionValid) {
            this.channels.push(channel);
            this.preSelections.push(preSelectionValid);

            if (this.channels.length === this.availablePlatforms.length && !this.preSelections.includes(false)) {
                this.moveDeploymentToNextStep();
                this.channels = [];
                this.preSelections = [];
            }
        },
        setCurrentCampaign(campaign) {
            this.campaign = campaign;
        },
        async handleSubmit() {
            if (this.campaign) {
                await this.handleSubmitCampaignName();
            }

            if (!this.error) {
                this.moveDeploymentToNextStep();
            }
        },
        async handleSubmitCampaignName() {
            try {
                this.loading = true;
                this.error = '';
                const allResponsesCampaigns = await this.createPromises(this.campaign, 'campaign');
                const allResponsesAdSets = await this.createPromises(this.campaign, 'ad-sets');
                for(const index in allResponsesCampaigns) {
                    const response = allResponsesCampaigns[index];
                    if(response.data.data.length) {
                        this.error = 'This Campaign name is already being used. Please select a different name.';
                        return;
                    }
                }
                for(const index in allResponsesAdSets) {
                    const response = allResponsesAdSets[index];
                    if(response.data.data.length) {
                        this.error = 'This Campaign name is already being used. Please select a different name.';
                        return;
                    }
                }
                this.$store.commit('adDeployment/setCampaignName', this.campaign);
            } catch(error) {
                console.log(error);
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
        async createPromises(value, objectType) {
            return Promise.all(
                this.selectedChannels.map(platform => {
                    const payload = {
                        dealerId: this.dealerId,
                        platform,
                        params: {
                            search_equals: true,
                            search: value,
                            stats: false,
                            page_size: 1
                        }
                    };
                    return new Promise((resolve, reject) => {
                        try {
                            const response = objectType === 'campaign'
                                ? this.$apiRepository.getDealerPlatformCampaigns(payload)
                                : this.$apiRepository.getDealerPlatformAdSets(payload);
                            resolve(response);
                        } catch(error) {
                            reject(error);
                        }
                    });
                })
            );
        },
        changesDynamicDeploymentPlatforms() {
            this.ads.forEach((ad, index) => {
                this.setAdDeploymentValue({
                    key: `ads.${index}.configuration.selected_platforms`,
                    value: this.selectedChannels,
                });
            });
        },
        moveDeploymentToNextStep() {
            if (this.deploymentType === DYNAMIC_DEPLOYMENT_TYPE) {
                this.changesDynamicDeploymentPlatforms();
            }

            this.goToNextStep();
        }
    }
};
</script>

<style lang="scss" scoped>
.channels{
    display:flex;
    width: 100%;
    text-align: center;
    justify-content: flex-start;
    align-items: flex-end;
    max-width: 800px;
    position: relative;
    margin: 0 auto 20px;
}
.edit-button {
    align-self: center;
}
.single-campaign-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .single-campaign {
        max-width: calc(100% - 100px);
        display: inline-block;
        background: white;
        padding: 10px 45px 10px 15px;
        border: 1px solid #DFDFE0;

        &__bottom {
            margin-top: 12px;
            position: relative;
            padding-left: 30px;

            &:after {
                content: '';
                position: absolute;
                width: 20px;
                height: 1px;
                background: #999999;
                top: 10px;
                left: 5px;
            }

            &:before {
                content: '';
                position: absolute;
                width: 1px;
                height: 15px;
                background: #999999;
                top: -5px;
                left: 5px;
            }
        }

        &__title {
            font-weight: 700;
            margin-right: 15px;
        }

        &__name {
            color: #03A2EA;
            font-style: italic;
        }

        &.multiple {
            position: relative;
            padding-left: 60px;

            .icon {
                position: absolute;
                top: 10px;
                left: 15px;
            }
        }
    }
}
.error-message {
    display: inline-block;
    border: 1px solid $error-500;
    background-color: rgba($error-500, 0.1);
    color: $error-500;
    padding: 5px 10px;
    margin: 10px 0;
}
.manual-deployment-panel {
    background-color: #FFDBD6;
    padding: 18px;
    width: 738px;
    margin: 42px 0 35px 0;

    li {
        padding-left: 20px;
    }
}
</style>
