<template>
    <div class="channel-wrapper">
        <div
            class="channel"
            :class="{
                'inactive': !active,
                'checked': checked,
                'not-enrolled': !hasPlatformAdProduct,
                'missing': incompleteFeatures.length && hasPlatformAdProduct,
                'no-eligible-ads': !ads.length && isStaticDeployment,
                'hidden': hidden
            }"
            @mouseover="hoverActive = true"
            @mouseleave="hoverActive = false">
            <div
                v-if="incompleteFeatures.length && hoverActive && hasPlatformAdProduct"
                class="selector-popup">
                <div class="selector-blue">
                    This channel is still missing dependencies:
                </div>
                <ul>
                    <li
                        v-for="feature in incompleteFeatures"
                        :key="feature.id">
                        {{ feature.display_name }}
                    </li>
                </ul>
            </div>
            <div
                v-if="forbidden && hoverActive"
                class="selector-popup">
                <div class="selector-blue">
                    This channel is supporting only video type of media
                </div>
            </div>
            <icon
                :name="channelDetails.icon_name"
                color="#8F9EA6"
                size="25" />
            <template v-if="active">
                <div class="name">
                    {{ channelDetails.display_name }}
                </div>
                <styled-loader
                    v-if="loading"
                    size="20" />
                <a
                    v-else-if="!hasPlatformAdProduct"
                    href="#"
                    @click.prevent="handleProductSelectionRouting">Setup</a>
                <a
                    v-else-if="incompleteFeatures.length"
                    href="#"
                    @click.prevent="handleOnboardingRouting">Complete</a>
                <styled-checkbox
                    v-else
                    ref="checkbox"
                    :key="checkboxKey"
                    :value="channel.name"
                    :disabled="isStaticDeployment && !ads.length"
                    :checked.sync="checked"
                    :row="false" />
            </template>
        </div>
        <div v-if="isStaticDeployment">
            <div
                v-if="active"
                class="ad-eligibility">
                <ads-tooltip
                    v-if="ads.length"
                    :ads="ads">
                    <styled-button text>
                        {{ ads.length }} eligible ads
                    </styled-button>
                </ads-tooltip>
                <styled-tooltip
                    v-else
                    position="right"
                    :max-width="300">
                    <template #content>
                        There are no ads in this deployment which
                        were designed for deployment on
                        {{ channelDetails.display_name }}.  To deploy
                        to this platform please create or select
                        ad(s) designed for use on
                        {{ channelDetails.display_name }}.
                    </template>
                    <styled-button text>
                        0 eligible ads
                    </styled-button>
                </styled-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledButton from '@/components/globals/StyledButton';
import AdsTooltip from '@/components/globals/AdsTooltip';
import StyledLoader from '@/components/globals/StyledLoader';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import Icon from '@/components/globals/Icon';
import { mapGetters, mapState } from 'vuex';
import { DEPLOYMENT_PLATFORMS, STATIC_DEPLOYMENT_TYPE } from '../../store/constants';
import {
    getChannelByPlatformName,
    getDealerChannelAdProducts
} from '@/components/onboarding/channel-setup/channels';

export default {
    components: {
        StyledLoader,
        StyledCheckbox,
        Icon,
        AdsTooltip,
        StyledButton,
        StyledTooltip
    },
    props: {
        channel: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            required: true
        },
        ads: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            hoverActive: false,
            checkboxKey: 0,
            forbidden: false,
            loading: false,
            isAuthorized: false,
        };
    },
    computed: {
         ...mapGetters([
             'dealerProducts',
             'activeDealerOnboardings',
        ]),
        ...mapGetters('adDeployment', [
            'isStaticDeployment'
        ]),
        ...mapState({
            dealer: state => state.dealer.currentDealer,
            featureStatus: state => state.dealer.featureStatus,
            agency: state => state.agency.currentAgency,
            deploymentType: state => state.adDeployment.deployment_type,
        }),
        channelDetails() {
            return getChannelByPlatformName(this.channel);
        },
        channelFeatures() {
            const deploymentType = this.isStaticDeployment
                ? STATIC_DEPLOYMENT_TYPE
                : this.deploymentType;

            return DEPLOYMENT_PLATFORMS
                .find(platform => (
                    platform.name === this.channel
                ))?.features(deploymentType) ?? [];
        },
        checked: {
            get() {
                return this.selectedChannels.includes(this.channel);
            },
            set(checked) {
                if (
                    checked &&
                    !this.selectedChannels.includes(this.channel)
                ) {
                    const newChannels = [ ...this.selectedChannels];
                    newChannels.push(this.channel);
                    this.selectedChannels = newChannels;
                } else if (!checked) {
                    const selectedIndex = this.selectedChannels.findIndex(channel => (
                        channel === this.channel
                    ));
                    if (selectedIndex > -1) {
                        const newChannels = [ ...this.selectedChannels];
                        newChannels.splice(selectedIndex, 1);
                        this.selectedChannels = newChannels;
                    }
                }
            }
        },
        selectedChannels: {
            get() {
                return this.$store.state.adDeployment.platforms;
            },
            set(value) {
                this.$store.commit('adDeployment/setPlatforms', value);
            }
        },
        platformAdProducts() {
            return getDealerChannelAdProducts(this.dealer, this.channel);
        },
        hasPlatformAdProduct() {
            return this.platformAdProducts.length;
        },
        platformOnboardings() {
            const matchProductPlatform = (product) => (
                product.platform_id === this.channelDetails.platform_id
            );

            return this.activeDealerOnboardings.filter((onboarding) => (
                onboarding.products?.data?.some(matchProductPlatform)
            ));
        },
        incompleteFeatures() {
            return this.featureStatus.filter(feature => (
                this.channelFeatures.includes(feature.feature)
                && feature.status !== 'complete'
            ));
        },
        hidden() {
            return (
                !this.active &&
                !this.selectedChannels.includes(this.channel)
            );
        },
    },
    async created() {
        this.loading = true;
        await Promise.all([
            this.getPlatformAuthStatus(),
            this.checkFeatureStatus()
        ]);
        if (this.active && this.selectedChannels.length) {
            this.checkSelection();
        }
        this.loading = false;
    },
    methods: {
        checkSelection() {
            let preSelectionValid = true;
            if (!this.hasPlatformAdProduct || (this.incompleteFeatures.length && this.hasPlatformAdProduct)) {
                const selectedIndex = this.selectedChannels.findIndex(channel => (
                    channel === this.channel
                ));
                if (selectedIndex > -1) {
                    const newChannels = [ ...this.selectedChannels];
                    newChannels.splice(selectedIndex, 1);
                    this.selectedChannels = newChannels;
                    preSelectionValid = false;
                }
            }
            this.$emit('selection-checked', this.channel, preSelectionValid);
        },
        handleOnboardingRouting() {

            if (this.platformOnboardings.length) {
                this.$router.push({
                    name: 'dealer-onboarding',
                    params: {
                        dealer_id: this.dealer.id,
                        onboarding_id: this.platformOnboardings?.[0].id
                    },
                    query: {
                        redirect_url: this.$route.fullPath
                    }
                });
            } else {
                this.$store.dispatch('startNewOnboarding', {
                    dealerId: this.dealer.id,
                    productIds: [ this.platformAdProducts?.[0].bb_product_id ],
                    redirectUrl: this.$route.fullPath
                });
            }


        },
        handleProductSelectionRouting() {
            this.$router.push({
                name: 'channel-setup-product-selection',
                params: {
                    dealer_id: this.dealer.id,
                    channel: this.channel
                },
                query: {
                    redirect_url: this.$route.fullPath
                }
            });
        },
        async getPlatformAuthStatus() {
            try {
                // @todo add Facebook to the repository
                const response = await this.$apiRepository.getPlatformAuthStatus(this.agency.id, this.channel);
                 if (response?.data?.token) {
                    this.isAuthorized = true;
                }
            } catch (error) {
                console.log('Error retrieving platform status');
            }
        },
        checkFeatureStatus() {
            if (this.notEnrolled) {
                return;
            }

            return this.$store.dispatch('updateDealerFeatureStatus', {
                features: this.channelFeatures,
            });
        }
    },
};
</script>


<style lang="scss" scoped>
.channel-wrapper {
    margin-right: 15px;
}
.channel {
    padding-top: 15px;
    width: 145px;
    height: 125px;
    position: relative;
    border: 1px solid #ABACAD;
    &.checked{
        border: 4px solid #03A2EA;
    }
    &.not-enrolled:not(.inactive) {
        margin-top: 30px;
        &:after{
            content: 'CHANNEL NOT SETUP';
            position: absolute;
            bottom: 100%;
            left: -1px;
            right: -1px;
            height: 28px;
            font-size: 11px;
            font-weight: 600;
            padding-top:5px;
            background: #EE5643;
            text-align: center;
            color: white;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
    &.missing:not(.inactive) {
        margin-top: 30px;
        &:after{
            content: 'MISSING DEPENDENCIES';
            position: absolute;
            bottom: 100%;
            left: -1px;
            right: -1px;
            height: 28px;
            font-size: 11px;
            font-weight: 600;
            padding-top:5px;
            background: #EA8202;
            text-align: center;
            color: white;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
    &.no-eligible-ads:not(.inactive) {
        margin-top: 30px;
        &:after{
            content: 'NO ELIGIBLE ADS';
            position: absolute;
            bottom: 100%;
            left: -1px;
            right: -1px;
            height: 28px;
            font-size: 11px;
            font-weight: 600;
            padding-top:5px;
            background: #EA8202;
            text-align: center;
            color: white;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
    &.inactive {
        width: 45px;
        height: 45px;
        border: 1px solid #ABACAD;
        background-color: $white;
        padding-top: 8px;
        > :not(svg){
            display: none;
        }
    }
    &.hidden{
        display: none;
    }
}
.coming-soon{
    font-style: italic;
}
.logo{
    max-width: 30px;
    max-height: 30px;
}
.name{
    color: #757575;
    margin-top: 5px;
    margin-bottom: 15px;
}
.selector-popup{
    width: 350px;
    text-align: left;
    position: absolute;
    box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.25);
    padding: 25px;
    bottom: calc(100% + 55px);
    left: 50%;
    transform: translateX(-50%);
    background: white;
    box-sizing: border-box;
    z-index: 999;
    &:after{
        content: '';
        position: absolute;
        width: 26px;
        height: 26px;
        transform: translateX(-50%) rotate(45deg) skew(-10deg, -10deg);
        bottom: -13px;
        left: 50%;
        background: white;
        box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.25);
    }
    &:before{
        content: '';
        position: absolute;
        background: white;
        width: 100px;
        height: 20px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
    }
}
.selector-blue{
    color: #EA8202;
    font-weight: bold;
    margin-bottom: 5px;
}
.ad-eligibility {
    margin-top: 10px;
}
</style>
