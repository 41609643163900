<template>
    <div class="campaign-container">
        <editable-data-field
            label="Campaign Name"
            :value="campaignName"
            :message="campaignMessage"
            :error="error"
            :open.sync="isCampaignOpen"
            @submit="saveCampaign" />
        <editable-data-field
            label="Special Ads Category"
            hide-save-button
            :message="categoryMessage"
            :open.sync="isCategoryOpen"
            :value="capitalizeWord(specialAdsCategoryName)">
            <template #content>
                <v-select
                    v-model="specialAdsCategory"
                    :items="specialAdCategories"
                    item-text="name"
                    item-value="value"
                    label="SPECIAL ADS CATEGORY"
                    class="mb-3 mt-4 styled-field campaign-category" />
                <styled-button
                    class="save-button"
                    small
                    @click="saveCategories">
                    Save
                </styled-button>
            </template>
        </editable-data-field>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import EditableDataField from '@/components/globals/fields/EditableDataField';
import StyledButton from '@/components/globals/StyledButton';
import { capitalizeWord } from '@/helpers/capitalizeWord';
import sleep from '@/helpers/sleep';
import { STATIC_DEPLOYMENT_TYPE } from '../../store/constants';

export default {
    name: 'AdDeploymentNewCampaignSelector',
    components: {
        EditableDataField,
        StyledButton
    },
    data() {
        return {
            capitalizeWord,
            isCampaignOpen: null,
            isCategoryOpen: null,
            campaignMessage: '',
            categoryMessage: '',
            campaign: '',
            error: '',
            specialAdCategories: [
                { name: 'Housing', value: 'housing' },
                { name: 'Credit', value: 'credit' },
                { name: 'Employment', value: 'employment' },
                { name: 'Issues Elections Politics', value: 'issues elections politics' },
                { name: 'None', value: 'none' }
            ],
        };
    },
    computed: {
        ...mapState({
            selectedPreset: (state) => state.adDeployment.selectedPreset,
            selectedChannels: (state) => state.adDeployment.platforms,
            dealerId: (state) => state.adDeployment.dealer_id,
            deploymentType: (state) => state.adDeployment.deployment_type,
        }),
        defaultCampaignName() {
            const dateFormat = this.$moment().format('MM/DD/YYYY hh:mm A');

            if (this.deploymentType === STATIC_DEPLOYMENT_TYPE) {
                return `Ad Launch (${this.selectedPreset?.title}) - ${dateFormat}`;
            }

            return `Inventory Ad Launch - ${dateFormat}`;
        },
        campaignName() {
            return this.campaign ? this.campaign : this.$store.state.adDeployment.campaign_selection.all_platforms.campaign_name;
        },
        specialAdsCategoryName() {
            return this.specialAdsCategory.ui_name || this.specialAdsCategory;
        },
        specialAdsCategory: {
            get() {
                return this.$store.state.adDeployment.campaign_selection.all_platforms.ad_category;
            },
            set(value) {
                this.$store.commit('adDeployment/setSpecialAdsCategory', value);
            }
        },
    },
    created() {
        if (!this.$store.state.adDeployment.campaign_selection.all_platforms?.campaign_name) {
            this.campaign = this.defaultCampaignName;
            this.$store.commit('adDeployment/setCampaignName', this.campaign);
        }
    },
    methods: {
        async saveCategories() {
            this.categoryMessage = 'Success';
            await sleep(1500);
            this.isCategoryOpen = false;
            this.categoryMessage = '';
        },
        async saveCampaign({ input }) {
            this.campaignMessage = 'Success';
            this.campaign = input;
            await sleep(1500);
            this.isCampaignOpen = false;
            this.campaignMessage = '';
            this.$emit('set-campaign', input);
        }
    }
};
</script>

<style lang="scss" scoped>
.campaign-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    justify-content: space-between;
}
.save-button {
    margin: 0 auto;
    display: flex;
}
</style>